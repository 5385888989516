table {
    width: 100%;
}

.table {
    width: 65%;
    max-width: 1000px;
}

.tableSection {
    display: flex;
    justify-content: center;
}

.details {
    margin-left: 20px;
    min-width: 300px;
    /*margin-top: 66px;*/
}

.detail-expand {
    background: transparent !important;
}

.mat-column-expandedDetail {
    padding: 0px !important;
}


@media only screen and (max-width: 1216px) {
    .tableSection {
        display: flex;
        justify-content: center;
    }

    .table {
        width: 100%;
    }

    .details {
        width: 100%;
        margin-left: 0px;
        margin-top: 0px;
    }
}

.mat-sort-header-content {
    color: white;
    font-weight: 450;
    font-size: 14px;
}

.mat-table {
    background: transparent;
    border-collapse: separate !important;
    border-spacing: 0 7px !important;
}

app-page-structure tbody tr:not(.mat-calendar-body tr, .mat-calendar-table-header tr){
    background: white !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
}

td {
    margin-bottom: 10px;
}

td.mat-cell,
th.mat-header-cell {
    border-bottom-color: transparent;
}

tr td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

tr td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

tr {
    cursor: pointer;
}

.mat-cell {
    color: var(--t) !important;
}

.cell-selected {
    transition: all 0.3s;
    color: var(--p) !important;
    background: var(--s) !important;
    overflow: hidden;
    font-weight: 450;
}

.cell-selected td {
    transition: all 0.3s;
    color: var(--p) !important;
}

.cell-selected td:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.mat-paginator {
    background: transparent;
}

table {
    width: 100%;
    table-layout: fixed;
}


/**Revisar**/
th div,
td>div:not(.mat-form-field-infix, .mat-form-field-appearance-outline, .mat-form-field-flex, .mat-form-field-outline, .mat-form-field-wrapper, .submitButton) {
    text-overflow: ellipsis;
    overflow: hidden;
}

tr.mat-header-row {
    background-color: var(--p);
    color: white;
    height: 47px !important;
}

th.mat-header-cell:last-of-type {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

th.mat-header-cell:first-of-type {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}