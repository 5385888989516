@import "../node_modules/@sinigual/angular-lib/src/lib/css/core-css.css";


:root {
    --sinigualColor: #2fbd96;
    --sinigualColorDark: #174236;
    --dangerColor: #f1395b;
    --customGray: rgba(0, 0, 0, 0.07);
    --lowGray: rgba(0, 0, 0, 0.02);
    --gray: #2c3342;
    --blue: #008BD0;
    --input: rgba(0, 0, 0, 0.2);
    --cc1: #2fbd96;
    --cc11: white;
    --cc2: #2fbd96;
    --cc22: white;

    --p: #2fbd96;
    --pb: #dbedef;
    /** --> Core-lib primary color*/
    --pdark: #1b765e;
    --s: #E6F7F2;
    --s2: #c8f5e8;
    --hs: #97efc9;

    --t: #2F4858;
    --t2: #979797;
    --t3: #E5E5E5;
    --t4: #f5f5f5;

    --r: #BD2F2F;
    --r2: #ffa5a5;
    --b: #008BD0;
    --b2: #C1E9FD;
    --b3: #dff4ff;
    --y: #fee32d;
    --y2: #fbf1ab;
    --o: #FE8854;
    --o2: #ffbfa5;
    --o3: #fff3ee;
    --contrast: #1c1c1c;
    --background: #F5F5F5;
    --gradient: linear-gradient(90deg, #3c8c76 0%, #2fbd96 48%, #6ccbd2 100%);
}

html {
    background-color: #f7f6f6 !important;
}

.g {
    color: var(--g);
}

.c_p {
    color: var(--p);
}

.c_r {
    color: var(--r);
}

.c_pb {
    color: var(--pb);
}

.bc_pb {
    background-color: var(--pb);
}

.c_o {
    color: var(--o);
}

.bc_s {
    background-color: var(--s);
}

.c_b {
    color: var(--b);
}

.c_b2 {
    color: var(--b2);
}

.bc_b {
    background-color: var(--b);
}

.bc_o {
    background-color: var(--o);
}

.bc_p {
    background-color: var(--p);
}

.c_t {
    color: var(--t);
}

.c_t2 {
    color: var(--t2);
}

mat-icon,
p {
    color: var(--t);
}

.mat-form-field-outline div {
    background-color: white;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Loading panel background */
.no-b mat-dialog-container {
    background-color: transparent;
    box-shadow: none;
}

.aie {
    align-items: flex-end;
}

.wrap-reverse,
.wr {
    flex-wrap: wrap-reverse;
}

/*
p, span{
    color: #363639;
}*/


.hidden {
    display: none !important;
}

.fsm {
    font-size: medium;
}

.bc {
    color: var(--blue);
}

.column {
    flex-direction: column;
}

.row {
    flex-direction: row;
}

.jcsb {
    justify-content: space-between;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

html,
body {
    height: 100%;
    width: 100%;
    background: #f7f6f6;
}

body {
    margin: 0px;
}


html {
    background-color: rgba(250, 250, 250, 250);
    min-height: 100% vh;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--sinigualColor);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: var(--sinigualColor);
}


.w100 {
    width: 100%;
}

.w100 mat-form-field {
    width: 100%;
}

.scd {
    color: var(--sinigualColorDark);
}

.aic {
    align-items: center;
}

.gcb {
    background: var(--gray) !important;
    color: white;
}


.dg {
    display: grid;
}

table th {
    text-align: center !important;
}

/*table th{
    background-color: #20ca9d;
    color: white !important;
    text-align: center !important;
}

tr th:nth-child(odd){
    background-color: var(--sinigualColor);
}*/

.mat-radio-container {
    margin-left: 15px;
}

/*Sinigual color text*/
.sc {
    color: var(--sinigualColor) !important;
}

/*Sinigual color background*/
.scb {
    background-color: var(--sinigualColor) !important;
    color: white;
}

.dc {
    color: var(--dangerColor) !important;
}

.dcb {
    background-color: var(--dangerColor);
    color: white;
}

.cg {
    color: var(--customGray);
}

.cgb {
    background-color: var(--customGray);
}

.x-large {
    font-size: x-large;
}

.xx-large {
    font-size: xx-large;
}

.larger {
    font-size: larger;
}

/*Text*/
.large {
    font-size: large;
}

.medium {
    font-size: medium;
}

.bold {
    font-weight: bold;
}

.semi-bold {
    font-weight: 500;
}


/* Margin */
.mb-20 {
    margin-bottom: 20px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mtb-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.bw {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.ma {
    margin: auto !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.mtb-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.mlr-10 {
    margin-right: 10px;
    margin-left: 10px
}

.mlr-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

/*Display*/
.db {
    display: block;
}

.df {
    display: flex;
}

.jcc {
    justify-content: center;
}

.wrap {
    flex-wrap: wrap;
}

.lighter {
    font-weight: lighter;
}

.dfc {
    display: flex;
    flex-direction: column;
}


/*Padding*/
.p15 {
    margin: 15px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: rgba(0, 0, 0, 0.02);
}

.tac {
    text-align: center;
}

.iconMedium {
    transform: scale(1.5);
}

.iconBig {
    transform: scale(2);
}

.iconLarge {
    transform: scale(5);
}

.fdc {
    display: flex;
    flex-direction: column;
}

.btnSearch {
    border-left: 1px solid var(--customGray);
    padding-left: 10px;
}

.roundBorder {
    border: 1px solid var(--customGray);
    border-radius: 20px;
}

.sinigualLogo {
    width: 150px;
    cursor: pointer;
}

@media only screen and (max-width: 442px) {
    .sinigualLogo {
        width: 30px;
        cursor: pointer;
    }
}

.mat-button-toggle-checked {
    background-color: var(--sinigualColor);
    font-weight: bold;
    color: white !important;
}

.content {
    margin: 20px;
    text-align: center;
}

.mw500>div {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.mw900>div {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.mt-30 {
    margin-top: 30px;
}

.custom-file-input {
    visibility: hidden;
    display: none;
}

.pointer {
    cursor: pointer;
}

.fit {
    object-fit: contain;
}


.smaller {
    font-size: smaller;
}

.mla {
    margin-left: auto !important;
}

.mra {
    margin-right: auto !important;
}


.round50 {
    border-radius: 50%;
}

.iconGray {
    color: #565656;
}

.full-screen-dialog {
    height: 100vh;
    width: 100vw !important;
    max-width: 100% !important;
}


.full-screen-modal .mat-dialog-container {
    max-width: 100% !important;
    flex: 100% !important;
}

.wsp {
    white-space: pre;
}

.mat-expansion-panel-content {
    padding: 0px 5px;
}

.mat-drawer-inner-container {
    min-width: 210px;
}

.mat-menu-content:not(:empty) {
    padding: 0px !important;
}

.mat-menu-panel {
    overflow: visible !important;
}


.mat-drawer-content {
    overflow: unset !important;
}

/**TODO
.datosContainerBody .cardParent .citaDiaCard:nth-child(odd) .mat-accordion mat-expansion-panel{
    background-color: var(--customGray);
}*/


.mat-accordion .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.mat-accordion .mat-expansion-panel:first-of-type {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.inputError {
    color: red;
    font-size: small;
}

.inputOk {
    color: var(--sinigualColor);
    font-size: small;
}

.container .phoneInput mat-form-field {
    margin-top: 5px;
    margin-bottom: 10px;
}


.holiday div .mat-form-field-flex {
    align-items: center !important;
}

.taj {
    text-align: justify;
}

.mat-tooltip {
    font-size: 12px !important;
    white-space: pre-line !important;
}

.textButton {
    cursor: pointer;
    color: var(--sinigualColor);
    text-decoration: underline;
}

/** Snack Service action*/
.mat-simple-snackbar-action {
    color: var(--sinigualColor) !important;
}

.pt-20 {
    padding-top: 20px;
}

.tcb {
    color: black;
}

.mat-form-field-wrapper {
    padding-bottom: 0px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 0.25em;
}

/**
.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--gray);
}*/

.faqsSection .faqsSelector:nth-child(1)>p.question {
    margin-top: 0px !important;
}

.companyDay .mat-calendar-body-cell-content {
    color: white !important;
    background-color: var(--r) !important;
}

.mini-companyDay .mat-calendar-body-cell-content::before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 7.5px;
    background-color: var(--r);
    margin-left: auto;
    margin-right: auto;
}

.subway-item .userDay .mat-calendar-body-cell-content {
    color: white !important;
    background-color: var(--sinigualColor) !important;
}

.holiday .mat-calendar-body-cell-content {
    color: var(--dangerColor) !important;
}

.mat-calendar-controls {
    margin: 0px !important;
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: none;
}


.cardLineContent .clientInfoLine:last-child p {
    margin-bottom: 3px;
}

.circle-button mat-icon {
    color: white;
}

input[type=color] {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

input[type=color]::-webkit-color-swatch {
    border: 1px solid var(--input);
    border-radius: 50%;
    padding: 0;
}

input[type=color]::-webkit-color-swatch-wrapper {
    border: 1px solid var(--input);
    border-radius: 10%;
    padding: 0;
    border: 0;
}

input[type="color" i] {
    padding: 0px;
    border: none;
}




.mat-calendar-body-selected {
    background-color: var(--cc2) !important;
    color: var(--cc22) !important;
}

.mat-datepicker-toggle-active {
    color: var(--cc2) !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: var(--cc2) !important;
}


.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: var(--cc2) !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: var(--cc2) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 255) inset !important;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    width: 20px;
    height: 20px;
}

.hover:hover span mat-icon {
    color: var(--sinigualColor);
    transition: 0.3s;
}

.nmb {
    margin-bottom: 0px !important;
}

.appButton {
    cursor: pointer;
    color: #2FBD96;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 16px;
    width: 101px;
    height: 36px;
    background: rgba(47, 189, 150, 0.1);
    border-radius: 4px;
    border: none;
}

.appButton:hover {
    cursor: pointer;
    color: #eff7f5;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    gap: 16px;
    width: 101px;
    height: 36px;
    background: rgba(47, 189, 151, 0.839);
    border-radius: 4px;
}

.uppercase {
    text-transform: uppercase;
}

.dn {
    display: none !important;
}

.mat-card.nostyle {
    border: unset !important;
    border-radius: unset !important;
    filter: unset !important;
    box-shadow: unset !important;
}

app-onboarding>mat-drawer-container>mat-drawer {
    width: 450px;
}

.onboarding-h {
    animation: at-ripple-pink 1s linear infinite;
    z-index: 9999 !important;
}


@-webkit-keyframes at-ripple-pink {
    0% {
        box-shadow: 0 4px 10px rgba(31, 90, 192, 0.2), 0 0 0 0 rgba(31, 90, 192, 0.2), 0 0 0 5px rgba(31, 90, 192, 0.2), 0 0 0 10px rgba(31, 90, 192, 0.2);
    }

    100% {
        box-shadow: 0 4px 10px rgba(31, 90, 192, 0.2), 0 0 0 5px rgba(31, 90, 192, 0.2), 0 0 0 10px rgba(31, 90, 192, 0.2), 0 0 0 20px rgb(47, 189, 150, 0);
    }
}

@keyframes at-ripple-pink {
    0% {
        box-shadow: 0 4px 10px rgba(31, 90, 192, 0.2), 0 0 0 0 rgba(31, 90, 192, 0.2), 0 0 0 5px rgba(31, 90, 192, 0.2), 0 0 0 10px rgba(31, 90, 192, 0.2);
    }

    100% {
        box-shadow: 0 4px 10px rgba(31, 90, 192, 0.2), 0 0 0 5px rgba(31, 90, 192, 0.2), 0 0 0 10px rgba(31, 90, 192, 0.2), 0 0 0 20px rgb(47, 189, 150, 0);
    }
}


/** SEARCH AND ADD HEADER **/

.search-add-header {
    margin-top: 30px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    max-width: 700px;
    justify-content: flex-start;
    flex-direction: column;
}

.add-button {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    transform: scale(0.90);
}

.flex80 {
    width: 85%;
}

@media only screen and (max-width: 500px) {
    .flex80 {
        width: 100%;
    }

    .add-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 10;
        transform: scale(1);
    }
}

.mat-drawer-container {
    background-color: white !important;
}

.mat-toolbar.mat-primary {
    background: transparent !important;
    color: var(--t) !important;
}

.mat-drawer-inner-container {
    height: auto !important;
}



/** APP-PAGE-STRUCTURE **/
.page-search {
    width: 460px;
}

@media only screen and (max-width: 683px) {

    .page-search-parent,
    .page-search {
        width: 100%;
    }
}

.circle-button {
    margin-left: 10px !important;
    background-color: var(--p);
}

.mat-chip.mat-standard-chip {
    color: var(--t) !important;
}

.suffix {
    color: gray;
    top: -4px;
    white-space: nowrap;
    flex: none;
    position: relative;
    padding: 5px;
    border-left: 1px solid #80808047;
}