.form mat-form-field{
    padding: 10px;
    padding-left: 0px;
    margin-left: 0px;
    box-sizing: border-box;
}

.form{
    max-width: 900px;
}

.form ._customInput{
    display: inline-block;
}

.mat-form-field-outline div{
    background-color: white;
}

.formw50 mat-form-field {
    box-sizing: border-box;
    width: 50%;
}


.w100 mat-form-field, .w100{
    width: 100%;
}

.w50 mat-form-field, .w50{
    width: 50%;
    flex-wrap: wrap;
}

.w50 mat-form-field, .w33{
    width: 33%;
}

.w66 mat-form-field, .w66{
    width: 66%;
}

/** THIS **/
.mat-form-field-wrapper{
    padding-bottom: 5px;
}

._customInput.w50 mat-form-field, ._customInput.w33 mat-form-field, ._customInput.w66 mat-form-field{
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .w100 mat-form-field, .w100{
        width: 100%;
    }
    
    .w50 mat-form-field, .w50{
        width: 50%;
    }
    
    .w50 mat-form-field, .w33{
        width: 50%;
    }
    
    .w66 mat-form-field, .w66{
        width: 50%;
    }

    .w33p{
        flex-wrap: wrap;
    }

    .w33p .w33:last-child {
        width: 100% !important;
    }
}

@media only screen and (max-width: 750px) {

    .wrap750{
        flex-wrap: wrap;
    }

    .wrap750 .w33, .wrap750 .w66{
        width: 100%;
    }

    .wrap750 .w33{
        justify-content: end;
    }

    .wrap750 mat-form-field{
       width: 100%;
    }
}


.double-card {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.double-card > .section {
    padding: 10px;
    width: 50%;
    box-sizing: border-box;
}

@media only screen and (max-width: 1000px) {
    .double-card > .section{
        width: 100%;
    }
}